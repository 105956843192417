//mixin to turn black and white an img
@mixin black-and-white() {
	filter: grayscale(100%); }

@mixin color() {
	filter: grayscale(0%); }

//mixin to manage responsive breakpoints
@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media screen and #{inspect(map-get($breakpoints, $breakpoint))} {
      @content; } }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. " + "Available breakpoints are: #{map-keys($breakpoints)}."; } }

//mixin to manage the BEM syntax
@mixin element($element) {
  &__#{$element} {
    @content; } }

@mixin modifier($modifier) {
  &--#{$modifier} {
    @content; } }

//mixin to center elements
@mixin center($horizontal: true, $vertical: true) {
  position: absolute;
  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0); }
  @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%); } }

//mixin to mantain aspect-ratio
@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%; }

  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; } }
