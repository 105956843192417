//typography
$font-family-base: 'Poppins', sans-serif;
$font-family-heading: $font-family-base;
$font-size-base: 1.6rem;

//media queries
// s(small) = 576px / 16px = 36em
// m(medium) = 768px / 16px = 48em
// l(large) = 992px / 16px = 62em
// xl(extra-large) = 1200px / 16px = 75em
$breakpoints: ('s': (max-width: 36em), 'm': (max-width: 48em), 'l': (max-width: 62em), 'xl': (max-width: 75em), 'grid': (min-width: 48em));

//nice colors
$color-aqua:  #7FDBFF;
$color-blue:  #0074D9;
$color-navy:  #001F3F;
$color-teal:  #39CCCC;
$color-green: #2ECC40;
$color-olive: #3D9970;
$color-lime:  #01FF70;
$color-yellow:  #FFDC00;
$color-orange:  #FF851B;
$color-red:     #FF4136;
$color-fuchsia: #F012BE;
$color-purple:  #B10DC9;
$color-maroon:  #85144B;
$color-black:  #111;
$color-dark-gray: #333;
$color-medium-gray: #999;
$color-light-gray: #ddd;
$color-white:  #fff;

//color pallete
$color-layout: #FDEE4B;

$color-interactive: $color-layout;
$color-interactive-dark: shade($color-interactive, 30%);
$color-interactive-darker: shade($color-interactive, 50%);
$color-interactive-light: tint($color-interactive, 30%);
$color-interactive-lighter: tint($color-interactive, 50%);

$color-gray-scale-darker: #1A1A1A;
$color-gray-scale-dark: #808080;
$color-gray-scale-light: #E6E6E6;
$color-gray-scale-lighter: #FAFAFA;

$color-system-error: $color-red;
$color-system-success: $color-green;

//border
$border-base: 1px solid $color-gray-scale-light;

//focus
$focus-outline-color: transparentize($color-interactive, 0.4);
$focus-outline-width: .2rem;
$focus-outline: $focus-outline-width solid $focus-outline-color;
$focus-outline-offset: .2rem;

//transition
$transition-duration-base: 160ms;
$transition-timing-base-bounce: $ease-in-out-back;
$transition-timing-base: $ease-in-quad;

//grid configuration
$grid-width: 124.8rem;
$grid-width-small: 48rem;
$grid-gutter-vertical: 6.4rem;
$grid-gutter-vertical-small: 4rem;
$grid-gutter-horizontal: 3.2rem;

//borders
$border-radius-base: .4rem;

//spacings
$spacing-t: .4rem;
$spacing-s: .8rem;
$spacing-m: 1.6rem;
$spacing-l: 2.4rem;
$spacing-xl: 3.2rem;
$spacing-xxl: 4.8rem;
