*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  // This defines what 1rem is: 1 rem = 10px(10px/16px = 62.5%)
  font-size: 62.5%;
  @include respond-to('s') {
    font-size: 55%; } }

body {
  box-sizing: border-box;
  border: 2.4rem solid $color-gray-scale-darker;
  @include respond-to('m') {
    border-width: .8rem; }
  @include respond-to('s') {
    border-width: 0; } }

::selection {
    background-color: $color-layout; }

//media
img {
  max-width: 100%;
  height: auto; }

main {
  @extend .row; }

//main with a max-width don't work in ie11
main {
  display: block; }
