//example
@keyframes scroll {
  0% {
    transform: translateY(-1.2rem); }

  50% {
    opacity: 1; }

  100% {
    // opacity: 0
    transform: translateY(1.2rem); } }
