%u-challenge-solution {
  margin-right: 8rem;
  max-width: 40rem;
  @include respond-to('l') {
    max-width: none;
    margin-right: 0;
    margin-bottom: 4rem; } }

%u-device {
  left: 50%;
  transform: translateX(-50%);
  overflow-y: scroll;
  @include respond-to('m') {
    width: 100%;
    overflow-y: visible;
    position: relative; } }

.title-and-btn-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include respond-to('l') {
    flex-direction: column;
    align-items: flex-start; }

  .jumbo {
    @include respond-to('s') {
      font-size: 4.5rem; } }

  .btn {
    margin-top: 14.5rem;
    margin-bottom: 8rem;
    @include respond-to('l') {
      margin-top: 0;
      margin-bottom: 4rem; }
    // +respond-to('m')
    //   position: absolute
    //   bottom: -9%
 }    //   position: absolute

  // .visit-the-site
  //   +respond-to('m')
  //     bottom: -1.2%
  //   +respond-to('s')
 }  //     bottom: -1.5%

.info-box {
  display: flex;
  @include respond-to('l') {
    flex-direction: column; }

  @include element('challenge') {
    @extend %u-challenge-solution; }

  @include element('solution') {
    @extend %u-challenge-solution; }

  @include element('role') {
    display: flex;
    margin-bottom: .8rem;
    min-width: 18.7rem; }


  @include element('date') {
    display: flex; } }

.devices {
  position: relative;
  margin-top: 14rem;
  @include respond-to('m') {
    margin-top: 8rem;
 }    // margin-bottom: 20rem

  //laptop
  @include element('laptop-img') {
    @include respond-to('m') {
      display: none; } }

  @include element('laptop-screen') {
    @include aspect-ratio(4, 2.495);
    @extend %u-device;
    position: absolute;
    width: 76.5%;
    border-radius: .8%;
    top: 4.8%;

    &:before {
      @include respond-to('m') {
        padding-top: 0; } } }

  @include element('laptop-website') {
    position: absolute;
    top: 0;
    @include respond-to('m') {
      position: relative; } }

  //phone
  @include element('phone-img') {
    @include respond-to('m') {
      display: none; } }

  @include element('phone-screen') {
    @include aspect-ratio(4, 7.1);
    @extend %u-device;
    position: absolute;
    top: 13.1%;
    width: 81.5%;

    &:before {
      @include respond-to('m') {
        padding-top: 0; } } }

  @include element('scrollable') {
    position: absolute;
    @include respond-to('m') {
      display: none; } }

  @include element('phone') {
    position: absolute;
    bottom: 0;
    left: -.6rem;
    max-width: 16.3%;
    @include respond-to('m') {
      position: relative;
      max-width: 100%;
      left: 0;
      margin-top: 4rem; } }


  @include element('phone-website') {
    position: absolute;
    top: 0;
    @include respond-to('m') {
      position: relative;
      width: 100%; } }

  //scrollable-indicator
  @include element('scrollable') {
    position: absolute;
    pointer-events: none;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12rem;
    height: 12rem;
    background-color: black(.7);
    border-radius: .4rem;
    @include respond-to('l') {
      width: 9rem;
      height: 9rem; }

    .p--white {
      position: absolute;
      bottom: .8rem;
      text-align: center;
      width: 100%; } }

  @include element('scrollable-icon') {
    animation: scroll .6s infinite;
    animation-direction: alternate;
    animation-timing-function: $transition-timing-base;
    max-width: 20%;
    margin: 0 auto;
    display: block;
    margin-top: 2.4rem; } }

.project-details {
  position: relative; }
