//spacings
// spacing-t: .4rem
// spacing-s: .8rem
// spacing-m: 1.6rem
// spacing-l: 2.4rem
// spacing-xl: 3.2rem
// spacing-xxl: 4.8rem

%u-center-text, .u-center-text {
  text-align: center; }

%u-mb-s .u-mb-s {
  margin-bottom: $spacing-s; }

%u-mb-m, .u-mb-m {
  margin-bottom: $spacing-m; }

%u-mb-l, .u-mb-l {
  margin-bottom: $spacing-l; }

%u-mb-xl, .u-mb-xl {
  margin-bottom: $spacing-xl; }

%u-mb-xxl, .u-mb-xxl {
  margin-bottom: $spacing-xxl; }

%u-mt-s, .u-mt-s {
  margin-bottom: $spacing-s; }

%u-mt-m, .u-mt-m {
  margin-top: $spacing-m; }

%u-mt-l, .u-mt-l {
  margin-top: $spacing-l; }

%u-mt-xl, .u-mt-xl {
   margin-top: $spacing-xl; }

%u-mt-xxl, .u-mt-xxl {
  margin-top: $spacing-xxl; }

%u-btn {
  border: .5rem solid $color-gray-scale-darker;
  color: contrast-switch($color-interactive);
  background-color: $color-interactive;
  position: relative;

  &:hover {
    color: $color-interactive;
    background-color: $color-gray-scale-darker; } }
