.bio {
  display: flex;
  align-items: flex-start;
  @include respond-to('xl') {
    flex-direction: column; }

  p {
    @include respond-to('xl') {
      width: 70%;
      margin: 0 auto; }
    @include respond-to('m') {
      width: 100%; }

    &:not(:last-child) {
      margin-bottom: 1.6rem; } }

  @include element('img') {
    margin-left: 10%;
    @include respond-to('xl') {
      order: -1;
      width: 70%;
      margin: 0 auto;
      margin-bottom: 8rem; }
    @include respond-to('m') {
      width: 100%; } } }
