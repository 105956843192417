.nav {

  @include element('list') {
    list-style: none; }

  @include element('item') {
    display: inline-block;
    @include respond-to('s') {
      display: block; }

    &:last-child {
      @include respond-to('m') {
        display: block; } }

    &:not(:last-child) {
      margin-right: 4.8rem;
      @include respond-to('m') {
        margin-bottom: 2.4rem; } } }

  @include element('link') {
    @extend .btn-text; } }
