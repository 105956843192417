@import url("https://fonts.googleapis.com/css?family=Poppins:400,700");
.u-center-text {
  text-align: center;
}

.u-mb-m {
  margin-bottom: 1.6rem;
}

.u-mb-l {
  margin-bottom: 2.4rem;
}

.u-mb-xl {
  margin-bottom: 3.2rem;
}

.u-mb-xxl {
  margin-bottom: 4.8rem;
}

.u-mt-s {
  margin-bottom: 0.8rem;
}

.u-mt-m {
  margin-top: 1.6rem;
}

.u-mt-l {
  margin-top: 2.4rem;
}

.u-mt-xl {
  margin-top: 3.2rem;
}

.u-mt-xxl {
  margin-top: 4.8rem;
}

.footer__social-link:link, .footer__social-link:visited, .btn:link, .btn:visited {
  border: 0.5rem solid #1A1A1A;
  color: #000;
  background-color: #FDEE4B;
  position: relative;
}

.footer__social-link:hover:link, .footer__social-link:hover:visited, .btn:hover:link, .btn:hover:visited {
  color: #FDEE4B;
  background-color: #1A1A1A;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

@media screen and (max-width: 36em) {
  html {
    font-size: 55%;
  }
}

body {
  box-sizing: border-box;
  border: 2.4rem solid #1A1A1A;
}

@media screen and (max-width: 48em) {
  body {
    border-width: .8rem;
  }
}

@media screen and (max-width: 36em) {
  body {
    border-width: 0;
  }
}

::selection {
  background-color: #FDEE4B;
}

img {
  max-width: 100%;
  height: auto;
}

main {
  display: block;
}

@keyframes scroll {
  0% {
    transform: translateY(-1.2rem);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(1.2rem);
  }
}

body {
  color: #1A1A1A;
  font-family: "Poppins", sans-serif;
}

.jumbo, .h1, .h2, .h3, .h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.jumbo, .h1 {
  letter-spacing: -.2rem;
}

.jumbo {
  font-size: 9rem;
  line-height: 9.9rem;
  margin-top: 16rem;
  margin-bottom: 8rem;
}

@media screen and (max-width: 48em) {
  .jumbo {
    font-size: 6.4rem;
    margin-bottom: 4rem;
    margin-top: 8rem;
  }
}

.h1 {
  font-size: 6.4rem;
  line-height: 7.2rem;
}

@media screen and (max-width: 48em) {
  .h1 {
    font-size: 6rem;
    margin-bottom: 4rem;
    margin-top: 8rem;
  }
}

.h2 {
  font-size: 4.5rem;
  line-height: 5.4rem;
  letter-spacing: -.15rem;
}

@media screen and (max-width: 36em) {
  .h2 {
    max-width: 36rem;
  }
}

.h3 {
  font-size: 3.2rem;
  line-height: 4.1rem;
  letter-spacing: -.1rem;
  margin-bottom: 2.4rem;
}

.h4 {
  font-size: 2.3rem;
  line-height: 3.2rem;
  margin-bottom: 2.4rem;
}

.p {
  font-size: 1.6rem;
  line-height: 2.8rem;
}

.p--white {
  color: #fff;
}

.label, .label--bold {
  font-size: 1.2rem;
  line-height: 2.2rem;
}

.label--bold {
  font-weight: 700;
  margin-right: .8rem;
}

.row, main {
  max-width: 48rem;
  padding: 0 1.6rem;
  margin: 0 auto;
}

.row::after, main::after {
  clear: both;
  content: "";
  display: block;
}

@media screen and (min-width: 48em) {
  .row, main {
    max-width: 124.8rem;
  }
}

.row [class^="col-"], main [class^="col-"] {
  float: left;
  width: 100%;
}

.row [class^="col-"]:not(:last-child), main [class^="col-"]:not(:last-child) {
  margin-right: 0;
}

@media screen and (min-width: 48em) {
  .row [class^="col-"]:not(:last-child), main [class^="col-"]:not(:last-child) {
    margin-right: 3.2rem;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 48em) {
  .row .col-1-of-2, main .col-1-of-2 {
    width: calc((100% - 3.2rem) / 2);
  }
}

@media screen and (min-width: 48em) {
  .row .col-1-of-3, main .col-1-of-3 {
    width: calc((100% - 2 * 3.2rem) / 3);
  }
}

@media screen and (min-width: 48em) {
  .row .col-2-of-3, main .col-2-of-3 {
    width: calc(2 * ((100% - 2 * 3.2rem) / 3) + 3.2rem);
  }
}

@media screen and (min-width: 48em) {
  .row .col-1-of-4, main .col-1-of-4 {
    width: calc((100% - 3 * 3.2rem) / 4);
  }
}

@media screen and (min-width: 48em) {
  .row .col-2-of-4, main .col-2-of-4 {
    width: calc(2 * ((100% - 3 * 3.2rem) / 4) + 3.2rem);
  }
}

@media screen and (min-width: 48em) {
  .row .col-3-of-4, main .col-3-of-4 {
    width: calc(3 * ((100% - 3 * 3.2rem) / 4) + 2 * 3.2rem);
  }
}

.header {
  margin-top: 2.4rem;
}

.header__logo-box {
  height: 6.4rem;
  transform: rotate(0);
  transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

@media screen and (max-width: 48em) {
  .header__logo-box {
    margin-bottom: 2.4rem;
    width: 6.4rem;
  }
}

.header__logo-box:hover {
  transform: rotate(180deg);
}

.header__logo {
  height: 6.4rem;
}

.header__upper-box {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 48em) {
  .header__upper-box {
    flex-direction: column;
  }
}

.header h1 {
  max-width: 87rem;
  margin-top: 16rem;
  margin-bottom: 20.4rem;
}

.nav__list {
  list-style: none;
}

.nav__item {
  display: inline-block;
}

@media screen and (max-width: 36em) {
  .nav__item {
    display: block;
  }
}

@media screen and (max-width: 48em) {
  .nav__item:last-child {
    display: block;
  }
}

.nav__item:not(:last-child) {
  margin-right: 4.8rem;
}

@media screen and (max-width: 48em) {
  .nav__item:not(:last-child) {
    margin-bottom: 2.4rem;
  }
}

.footer {
  margin: 18.4rem 0 18.4rem;
}

@media screen and (max-width: 48em) {
  .footer {
    margin: 14rem 0 14rem;
  }
}

.footer h2 {
  max-width: 93.1rem;
  margin-bottom: 4rem;
}

@media screen and (max-width: 36em) {
  .footer h2 {
    max-width: 36rem;
  }
}

.footer svg {
  height: 100%;
  transition: all cubic-bezier(0.55, 0.085, 0.68, 0.53) 160ms;
  fill: #1A1A1A;
}

.footer__contact-ways {
  display: flex;
}

@media screen and (max-width: 48em) {
  .footer__contact-ways {
    flex-direction: column;
  }
}

.footer__mail-box {
  margin-right: .8rem;
}

@media screen and (max-width: 48em) {
  .footer__mail-box .btn {
    width: 100%;
    max-width: 30rem;
    text-align: center;
  }
}

@media screen and (max-width: 48em) {
  .footer__mail-box .btn span:first-child {
    display: none;
  }
}

.footer__mail-box .btn span:last-child {
  display: none;
}

@media screen and (max-width: 48em) {
  .footer__mail-box .btn span:last-child {
    display: block;
  }
}

.footer__social-link:link, .footer__social-link:visited {
  text-align: center;
  width: 6.9rem;
  height: 6.9rem;
}

@media screen and (max-width: 48em) {
  .footer__social-link:link, .footer__social-link:visited {
    margin-top: .8rem;
  }
}

.footer__social-link:hover svg {
  fill: #FDEE4B;
}

.footer__social-link:not(:last-child) {
  margin-right: .8rem;
}

a:link, a:visited {
  font-size: 1.2rem;
  letter-spacing: .3rem;
  font-weight: 700;
  line-height: 1.9rem;
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 160ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

a:active, a:hover {
  outline-width: 0;
}

.btn:link, .btn:visited {
  padding: 2rem 4rem;
}

.btn-text:link, .nav__link:link, .btn-text:visited, .nav__link:visited {
  color: #1A1A1A;
}

.btn-text:link, .nav__link:link, .btn-text:visited, .nav__link:visited {
  line-height: 2.5rem;
  position: relative;
}

.btn-text:after, .nav__link:after, .btn-text:before, .nav__link:before {
  content: '';
  position: absolute;
  width: 0rem;
  height: .5rem;
  bottom: -.2rem;
  background-color: #1A1A1A;
  transition: all 160ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.btn-text:before, .nav__link:before {
  left: calc(50%);
}

.btn-text:after, .nav__link:after {
  right: calc(50%);
}

.btn-text:hover:after, .nav__link:hover:after, .btn-text:hover:before, .nav__link:hover:before {
  width: 50%;
}

table {
  border-collapse: collapse;
  table-layout: fixed;
  text-align: left;
  width: 100%;
}

tr {
  border-bottom: 1px solid #E6E6E6;
}

th {
  font-weight: bold;
}

th,
td {
  padding: 1.6rem 1.6rem 1.6rem 0;
}

.projects {
  margin-bottom: 18.4rem;
}

.projects h2 {
  margin-bottom: 12rem;
}

.project-thumbnail {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 62em) {
  .project-thumbnail {
    flex-direction: column;
    align-items: flex-start;
  }
}

.project-thumbnail:not(:last-child) {
  margin-bottom: 18.4rem;
}

.project-thumbnail__text-box {
  max-width: 48rem;
  margin-right: 5rem;
}

@media screen and (max-width: 62em) {
  .project-thumbnail__text-box {
    max-width: none;
    margin-right: 0;
    order: 2;
    margin-top: 8rem;
  }
}

@media screen and (max-width: 48em) {
  .project-thumbnail__text-box {
    margin-top: 4rem;
  }
}

.project-thumbnail__img {
  max-width: 58.4rem;
}

.project-thumbnail .p {
  margin-bottom: 1.6rem;
}

.project-thumbnail__role {
  display: flex;
  align-items: center;
  margin-bottom: 3.2rem;
}

.project-thumbnail__role .label:nth-child(3), .project-thumbnail__role .label--bold:nth-child(3) {
  margin-left: 2.4rem;
  position: relative;
}

.project-thumbnail__role .label:nth-child(3):before, .project-thumbnail__role .label--bold:nth-child(3):before {
  content: '';
  position: absolute;
  width: .2rem;
  height: .8rem;
  top: 45%;
  transform: translateY(-50%);
  left: -1.3rem;
  background-color: #1A1A1A;
}

.ways-help-you h2 {
  margin-bottom: 8.8rem;
}

.ways-help-you__box {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 48em) {
  .ways-help-you__box {
    flex-direction: column;
  }
}

.way-help-you {
  max-width: 54.1rem;
}

.way-help-you:not(:last-child) {
  margin-right: 5rem;
}

@media screen and (max-width: 48em) {
  .way-help-you:not(:last-child) {
    margin-right: 0;
    margin-bottom: 5.6rem;
  }
}

.way-help-you__img {
  width: 8rem;
}

.way-help-you__img-box {
  height: 10.4rem;
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;
}

.info-box__challenge, .info-box__solution {
  margin-right: 8rem;
  max-width: 40rem;
}

@media screen and (max-width: 62em) {
  .info-box__challenge, .info-box__solution {
    max-width: none;
    margin-right: 0;
    margin-bottom: 4rem;
  }
}

.devices__laptop-screen, .devices__phone-screen {
  left: 50%;
  transform: translateX(-50%);
  overflow-y: scroll;
}

@media screen and (max-width: 48em) {
  .devices__laptop-screen, .devices__phone-screen {
    width: 100%;
    overflow-y: visible;
    position: relative;
  }
}

.title-and-btn-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 62em) {
  .title-and-btn-box {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (max-width: 36em) {
  .title-and-btn-box .jumbo {
    font-size: 4.5rem;
  }
}

.title-and-btn-box .btn {
  margin-top: 14.5rem;
  margin-bottom: 8rem;
}

@media screen and (max-width: 62em) {
  .title-and-btn-box .btn {
    margin-top: 0;
    margin-bottom: 4rem;
  }
}

.info-box {
  display: flex;
}

@media screen and (max-width: 62em) {
  .info-box {
    flex-direction: column;
  }
}

.info-box__role {
  display: flex;
  margin-bottom: .8rem;
  min-width: 18.7rem;
}

.info-box__date {
  display: flex;
}

.devices {
  position: relative;
  margin-top: 14rem;
}

@media screen and (max-width: 48em) {
  .devices {
    margin-top: 8rem;
  }
}

@media screen and (max-width: 48em) {
  .devices__laptop-img {
    display: none;
  }
}

.devices__laptop-screen {
  position: relative;
  position: absolute;
  width: 76.5%;
  border-radius: .8%;
  top: 4.8%;
}

.devices__laptop-screen:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 62.375%;
}

.devices__laptop-screen > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media screen and (max-width: 48em) {
  .devices__laptop-screen:before {
    padding-top: 0;
  }
}

.devices__laptop-website {
  position: absolute;
  top: 0;
}

@media screen and (max-width: 48em) {
  .devices__laptop-website {
    position: relative;
  }
}

@media screen and (max-width: 48em) {
  .devices__phone-img {
    display: none;
  }
}

.devices__phone-screen {
  position: relative;
  position: absolute;
  top: 13.1%;
  width: 81.5%;
}

.devices__phone-screen:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 177.5%;
}

.devices__phone-screen > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media screen and (max-width: 48em) {
  .devices__phone-screen:before {
    padding-top: 0;
  }
}

.devices__scrollable {
  position: absolute;
}

@media screen and (max-width: 48em) {
  .devices__scrollable {
    display: none;
  }
}

.devices__phone {
  position: absolute;
  bottom: 0;
  left: -.6rem;
  max-width: 16.3%;
}

@media screen and (max-width: 48em) {
  .devices__phone {
    position: relative;
    max-width: 100%;
    left: 0;
    margin-top: 4rem;
  }
}

.devices__phone-website {
  position: absolute;
  top: 0;
}

@media screen and (max-width: 48em) {
  .devices__phone-website {
    position: relative;
    width: 100%;
  }
}

.devices__scrollable {
  position: absolute;
  pointer-events: none;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12rem;
  height: 12rem;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: .4rem;
}

@media screen and (max-width: 62em) {
  .devices__scrollable {
    width: 9rem;
    height: 9rem;
  }
}

.devices__scrollable .p--white {
  position: absolute;
  bottom: .8rem;
  text-align: center;
  width: 100%;
}

.devices__scrollable-icon {
  animation: scroll .6s infinite;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  max-width: 20%;
  margin: 0 auto;
  display: block;
  margin-top: 2.4rem;
}

.project-details {
  position: relative;
}

.bio {
  display: flex;
  align-items: flex-start;
}

@media screen and (max-width: 75em) {
  .bio {
    flex-direction: column;
  }
}

@media screen and (max-width: 75em) {
  .bio p {
    width: 70%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 48em) {
  .bio p {
    width: 100%;
  }
}

.bio p:not(:last-child) {
  margin-bottom: 1.6rem;
}

.bio__img {
  margin-left: 10%;
}

@media screen and (max-width: 75em) {
  .bio__img {
    order: -1;
    width: 70%;
    margin: 0 auto;
    margin-bottom: 8rem;
  }
}

@media screen and (max-width: 48em) {
  .bio__img {
    width: 100%;
  }
}
