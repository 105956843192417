a {
  &:link,
  &:visited {
    font-size: 1.2rem;
    letter-spacing: .3rem;
    font-weight: 700;
    line-height: 1.9rem;
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;

    transition: all $transition-duration-base $transition-timing-base; }

  &:active, &:hover {
    outline-width: 0; } }

.btn {
  &:link,
  &:visited {
    padding: 2rem 4rem;
    @extend %u-btn; } }

.btn-text {
  &:link,
  &:visited {
    color: $color-gray-scale-darker; }

  &:hover {
 } }    // color: $color-interactive-dark


.btn-text {
  &:link,
  &:visited {
    line-height: 2.5rem;
    position: relative; }
  &:after,
  &:before {
    content: '';
    position: absolute;
    width: 0rem;
    height: .5rem;
    bottom: -.2rem;
    background-color: $color-gray-scale-darker;
    transition: all $transition-duration-base $transition-timing-base-bounce; }

  &:before {
    left: calc(50%); }

  &:after {
    right: calc(50%); }

  &:hover {
    &:after, &:before {
      width: 50%; } } }


%u-btn-text {
  &:link, &:visited {}


  &:hover {
 } }    // color: shade($color-primary, 25%)
