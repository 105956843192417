table {
  border-collapse: collapse;
  table-layout: fixed;
  text-align: left;
  width: 100%; }

tr {
  border-bottom: $border-base; }

th {
  font-weight: bold; }

th,
td {
  padding: $spacing-m $spacing-m $spacing-m 0; }
