.footer {
  margin: 18.4rem 0 18.4rem;
  @include respond-to('m') {
    margin: 14rem 0 14rem; }

  h2 {
    max-width: 93.1rem;
    margin-bottom: 4rem;
    @include respond-to('s') {
      max-width: 36rem; } }

  svg {
    height: 100%;
    transition: all $transition-timing-base $transition-duration-base;
    fill: $color-gray-scale-darker; }

  @include element('contact-ways') {
    display: flex;
    @include respond-to('m') {
      flex-direction: column; } }

  @include element('mail-box') {
    margin-right: .8rem;

    .btn {
      @include respond-to('m') {
        width: 100%;
        max-width: 30rem;
        text-align: center; } }

    .btn span:first-child {
      @include respond-to('m') {
        display: none; } }

    .btn span:last-child {
      display: none;
      @include respond-to('m') {
        display: block; } } }


  @include element('social-box');


  @include element('social-link') {
    &:link,
    &:visited {
      text-align: center;
      width: 6.9rem;
      height: 6.9rem;
      @extend %u-btn;
      @include respond-to('m') {
        margin-top: .8rem; } }

    &:hover {
      svg {
        fill: $color-interactive; } }

    &:not(:last-child) {
      margin-right: .8rem; } } }
