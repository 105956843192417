//fonts
@import url('https://fonts.googleapis.com/css?family=Poppins:400,700');

//typography rules
body {
  color: $color-gray-scale-darker;
  font-family: $font-family-base; }

.jumbo, .h1, .h2, .h3, .h4 {
  font-family: $font-family-heading;
  font-weight: 700; }

.jumbo, .h1 {
  letter-spacing: -.2rem; }

.jumbo {
  font-size: 9rem;
  line-height: 9.9rem;
  margin-top: 16rem;
  margin-bottom: 8rem;
  @include respond-to('m') {
    font-size: 6.4rem;
    margin-bottom: 4rem;
    margin-top: 8rem; } }

.h1 {
  font-size: 6.4rem;
  line-height: 7.2rem;
  @include respond-to('m') {
    font-size: 6rem;
    margin-bottom: 4rem;
    margin-top: 8rem; } }

.h2 {
  font-size: 4.5rem;
  line-height: 5.4rem;
  letter-spacing: -.15rem;
  @include respond-to('s') {
    max-width: 36rem; } }

.h3 {
  font-size: 3.2rem;
  line-height: 4.1rem;
  letter-spacing: -.1rem;
  margin-bottom: 2.4rem; }

.h4 {
  font-size: 2.3rem;
  line-height: 3.2rem;
  margin-bottom: 2.4rem; }

.p {
  font-size: $font-size-base;
  line-height: 2.8rem;

  @include modifier('white') {
    color: #fff; } }

.label {
  font-size: 1.2rem;
  line-height: 2.2rem;

  @include modifier('bold') {
    @extend .label;
    font-weight: 700;
    margin-right: .8rem; } }
